@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.css';
@import '~font-awesome/css/font-awesome.min.css';
/*============================================
  Colors
=============================================*/
.text-blue {
  color: #1a465d !important;
}

/*============================================
  Backgrounds
=============================================*/
.bg-blue {
  background-color: #1a465d !important;
}

/*============================================
  Section Headers
=============================================*/
section h2.header-black {
  color: #1a465d;
  text-align: center;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  margin: 0 0 50px;
}

section h2.header-white {
  color: #ffffff;
  text-align: center;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  margin: 0 0 50px;
}

/*============================================
  Dark blue link with arrow
=============================================*/
.arrow-link-blue-dark {
  display: inline-block;
  color: #1a465d;
}

.arrow-link-blue-dark:hover {
  text-decoration: none;
  color: #1a465d;
}

.arrow-link-blue-dark:after {
  font-family: FontAwesome;
  content: "";
  left: 8px;
  position: relative;
  color: #1a465d;
}

/*============================================
  Light blue link with arrow
=============================================*/
.arrow-link-blue-light {
  display: inline-block;
  color: #3eb9ef;
}

.arrow-link-blue-light:hover {
  text-decoration: none;
  color: #3eb9ef;
}

.arrow-link-blue-light:after {
  font-family: FontAwesome;
  content: "";
  left: 8px;
  position: relative;
  color: #3eb9ef;
}

/*============================================
  White link with arrow
=============================================*/
.arrow-link-white {
  display: inline-block;
  color: #FFFFFF;
}

.arrow-link-white:hover {
  text-decoration: none;
  color: #FFFFFF;
}

.arrow-link-white:after {
  font-family: FontAwesome;
  content: "";
  left: 8px;
  position: relative;
  color: #FFFFFF;
}